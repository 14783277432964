<template>
	<div class="diy_list page_material_information" id="material_information_list">
		<div class="warp">
			<div class="container diy_list_container">
				<div class="diy_list_title">
					<div class="col">
						<span class="title">物资信息列表</span>
					</div>
				</div>
				<div class="row diy_list_search">
					<div class="col">
						<!-- 搜索栏 -->
						<div class="view">
							<span class="diy_list_search_title">关键字：</span>
									<b-form-input size="sm" class="mr-sm-2" placeholder="物资名称搜索" v-model="query['item_name']" />
										<b-form-input size="sm" class="mr-sm-2" placeholder="类别搜索" v-model="query['category_shown']" />
											<b-button size="sm" @click="search()" >
								<b-icon icon="search"/>
							</b-button>
						</div>
						<!-- /搜索栏 -->
					</div>
				</div>
				<div class="diy_list_select_box">
					<span class="diy_list_select_title">下拉搜索：</span>
						<div class="diy_list_dropdown_box">
						<div class="col">
							<!-- 筛选 -->
							<div class="view">
																	<b-dropdown text="类别" variant="outline-dark" left>
									<b-dropdown-item @click="filter_set('全部','category_shown')">全部</b-dropdown-item>
										<b-dropdown-item v-for="(o, i) in list_category_shown" :key="i" @click="filter_set(o['classification_name'],'category_shown')" >
												{{ o['classification_name'] }}
										</b-dropdown-item>
								</b-dropdown>
													</div>
							<!-- /筛选 -->
						</div>
					</div>
					<div class="diy_list_sort_box">
						<div class="col">
							<!-- 排序 -->
							<div class="view">
								<b-dropdown text="排序" variant="outline-dark" left>
										<b-dropdown-item v-for="(o, i) in list_sort" :key="i" @click="set_sort(o)" >
												{{ o.name }}
										</b-dropdown-item>
								</b-dropdown>
							</div>
							<!--/排序 -->
						</div>
					</div>
				</div>

				<div class="row diy_list_box">
					<div class="col">
						<!-- 列表 -->
						<list_material_information :list="list" />
						<!-- /列表 -->
					</div>
				</div>
				<div class="row diy_list_page_box">
					<div class="col overflow-auto flex_cc">
						<!-- 分页器 -->
<!--						<diy_pager v-model="query['page']" :size="query['size']" :count="count" v-on:toPage="toPage" v-on:toSize="toSize" ></diy_pager>-->
            <b-pagination
                v-model="query.page"
                :total-rows="count"
                :per-page="query.size"
                @change="goToPage"
            />
						<!-- /分页器 -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import list_material_information from "@/components/diy/list_material_information.vue";
	import diy_pager from "@/components/diy/diy_pager";
	import mixin from "@/mixins/page.js";

	export default {
		mixins: [mixin],
		components: {
			diy_pager,
			list_material_information
		},
		data() {
			return {
				url_get_list: "~/api/material_information/get_list?like=0",

				// 查询条件
				oauth: {
					"signIn": true,
					"user_group": []
				},
				query: {
					keyword: "",
					page: 1,
					size: 10,
									"item_name": "", // 物资名称
												"category_shown": "", // 所示类别
										},

				// 排序内容
				list_sort: [{
						name: "创建时间从高到低",
						value: "create_time desc",
					},
					{
						name: "创建时间从低到高",
						value: "create_time asc",
					},
					{
						name: "更新时间从高到低",
						value: "update_time desc",
					},
					{
						name: "更新时间从低到高",
						value: "update_time asc",
					},
							{
						name: "物资名称正序",
						value: "item_name asc",
					},
					{
						name: "物资名称倒序",
						value: "item_name desc",
					},
								{
						name: "所示类别正序",
						value: "category_shown asc",
					},
					{
						name: "所示类别倒序",
						value: "category_shown desc",
					},
								],

											// 所示类别列表
				"list_category_shown": [""],
								
			}
		},
		methods: {
      get_list_before(param) {
      },
			/**
			 * 筛选选择
			 */
			filter_set(o,key) {
			    if (o == "全部") {
			        this.query[key] = "";
			    } else {
			        this.query[key] = o;
			    }
			    this.search();
			},

			/**
			 * 排序
			 */
			set_sort(o) {
			    this.query.orderby = o.value;
			    this.search();
			},


							/**
			 * 获取所示类别列表
			 */
			async get_list_category_shown() {
				var json = await this.$get("~/api/material_classification/get_list?");
				if (json.result) {
					this.list_category_shown = json.result.list;
				} else if (json.error) {
					console.log(json.error);
				}
			},
				
			/**
			 * 筛选
			 */
														filter_category_shown(o) {
				if (o == "全部") {
					this.query["category_shown"] = "";
				} else {
					this.query["category_shown"] = o;
				}
				this.search();
			},
											/**
			 * 重置
			 */
			reset() {
								this.query.item_name = ""
											this.query.category_shown = ""
										this.search();
			},

			// 返回条数
			toSize(i){
				this.query.size = i;
				this.first();
			},

			// 返回页数
			toPage(i){
				this.query.page = i;
				this.first();
			},

      goToPage(v){
        this.query.page = v;
        this.goToNew(v)
      },

		},
		computed: {
		},
		created() {
										/**
			 * 获取所示类别列表
			 */
			this.get_list_category_shown();
										}
	}
</script>

<style>
</style>
